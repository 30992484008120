import { HoverCardPortal } from '@radix-ui/react-hover-card';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, SimpleCardHeader } from '@/Card';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/HoverCard';
import { CorrelationIcon, SimpleCardHeaderIcon } from '@/Icon';
import { Helper, MutedText, NumericValue, SimpleCardTitle, Strong } from '@/Text';
import { cn } from '~/utils/cn';

import { DataPoint } from './correlation-utils';

interface CorrelationGraphProps {
  data: DataPoint[];
  xLabels: string[];
  yLabels: string[];
  colorMap: { [key: string]: string };
  xAxisLabel?: string;
  yAxisLabel?: string;
  tiltXLabels?: boolean; // New prop for tilting x-axis labels
}

const CorrelationGraph: React.FC<CorrelationGraphProps> = ({
  data,
  xLabels,
  yLabels,
  colorMap,
  xAxisLabel,
  yAxisLabel,
  tiltXLabels = true,
}) => {
  const { t } = useTranslation();
  const [cellSize, setCellSize] = useState(40);
  const containerRef = useRef<HTMLDivElement>(null);

  const getCellValue = (x: string, y: string) => {
    const dataPoint = data.find(d => d[xAxisLabel || 'x'] === x);
    return dataPoint ? dataPoint[y] : 0;
  };

  const getCellCategory = (value: number) => {
    if (!value) return 'Null';
    if (value < 0.25) return 'Very Weak';
    if (value < 0.50) return 'Weak';
    if (value < 0.75) return 'Strong';
    return 'Very Strong';
  };

  useEffect(() => {
    const updateCellSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const yAxisLabelWidth = 80; // Adjust this value based on your y-axis label width
        const availableWidth = containerWidth - yAxisLabelWidth;
        const maxCellsInRow = Math.max(xLabels.length, yLabels.length);
        const newCellSize = Math.floor(availableWidth / (maxCellsInRow + 1)); // +1 for y-axis labels
        setCellSize(Math.max(Math.min(newCellSize, 60), 20)); // Set a minimum of 20px and maximum of 60px
      }
    };

    updateCellSize();
    window.addEventListener('resize', updateCellSize);
    return () => window.removeEventListener('resize', updateCellSize);
  }, [xLabels.length, yLabels.length]);

  return (
    <div className="w-full" ref={containerRef}>
      <div className="flex">
        {/* Y-Axis */}
        {yAxisLabel && (
          <div aria-label="Y Axis" className="w-8 mr-2 flex items-center justify-center">
            <span className="transform -rotate-90 whitespace-nowrap">
              <Helper>
                {yAxisLabel}
              </Helper>
            </span>
          </div>
        )}

        <div className="flex-grow overflow-x-auto">
          <div className="grid justify-start" style={{
            gridTemplateColumns: `auto repeat(${xLabels.length}, ${cellSize}px)`,
            gridTemplateRows: `auto repeat(${yLabels.length}, ${cellSize}px)`,
            gap: '1px',
          }}>
            {/* Empty top-left cell */}
            <div/>

            {/* X-axis labels */}
            {xLabels.map(label => (
              <div key={label}
                className={cn(
                  'text-xxs text-center text-gray-600 border-r border-gray-200 flex items-center justify-center',
                  tiltXLabels && 'h-16' // Increase height when labels are tilted
                )}
              >
                <span
                  className={cn(
                    'inline-block text-xxxs',
                    tiltXLabels && 'transform -rotate-45 origin-top-left translate-y-5'
                  )}
                >
                  {label}
                </span>
              </div>
            ))}

            {/* Y-axis labels and data cells */}
            {yLabels.map((yLabel) => (
              <React.Fragment key={yLabel}>
                <div
                  className="text-xs text-right pr-2 text-gray-600 flex items-center justify-end border-t border-r border-gray-200 border-solid">
                  {yLabel}
                </div>
                {xLabels.map((xLabel) => {
                  const cellValue = getCellValue(xLabel, yLabel);
                  const category = getCellCategory(cellValue);
                  const color = colorMap[category];
                  return (
                    <div
                      key={`${xLabel}-${yLabel}`}
                      className="relative"
                      style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                    >
                      <HoverCard openDelay={100} closeDelay={0}>
                        <HoverCardTrigger>
                          <motion.div
                            data-testid={`correlation-cell-${cellValue}`}
                            className={cn(
                              'w-full h-full rounded-sm cursor-pointer',
                              colorMap[category]
                            )}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                          />
                        </HoverCardTrigger>
                        <HoverCardPortal>
                          <HoverCardContent flat>
                            <Card className="!shadow-xl">
                              <SimpleCardHeader>
                                <SimpleCardHeaderIcon icon={CorrelationIcon}/>
                                <SimpleCardTitle>
                                  {t('correlation_strength')}
                                </SimpleCardTitle>
                              </SimpleCardHeader>
                              <CardBody size="xs">
                                <div>
                                  <Strong className="text-xs">
                                    {xLabel} vs {yLabel}
                                  </Strong>
                                </div>

                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <div className={cn('w-3 h-3 mr-1 rounded-sm', color)}/>
                                    <MutedText>
                                      {category}
                                    </MutedText>
                                  </div>

                                  <div>
                                    <NumericValue className="ml-2">
                                      {cellValue.toFixed(2)}
                                    </NumericValue>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </HoverCardContent>
                        </HoverCardPortal>
                      </HoverCard>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>

          {xAxisLabel && (
            <div className="text-center mt-2">
              <Helper>
                {xAxisLabel}
              </Helper>
            </div>
          )}
        </div>
      </div>

      <hr/>

      <div className="mt-4 flex flex-wrap">
        {Object.entries(colorMap).map(([key, color]) => (
          <div key={key} className="flex items-center mr-4 mb-2">
            <div className={cn('w-3 h-3 mr-1 rounded-sm', color)}/>
            <span className="text-xs text-gray-600 capitalize">{key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CorrelationGraph;
