import { ReactNode } from 'react';

import { ApprovedIcon, LabelIcon, MeasurementFieldIcon, ProduceIcon, TransportTemperatureIcon } from '@/Icon';
import { Label } from '@/Label';
import { Breadcrumbs, LayoutBody, LayoutHeader } from '@/Layout';
import { MutedText, PageTitle, SectionDescription, SectionTitle } from '@/Text';
import { SectionLayout, WithSectionIcon } from '~/Components/Common/Section';
import { FormatProduceName } from '~/Components/Produce/FormatProduce';
import { ProduceImage } from '~/Components/Produce/ProduceImage';
import { SpecPageProps } from '~/types/page-props';

import SettingsLayout from '../../SettingsLayout';
import { ProduceTempForm } from './ProduceTempForm';
import { SpecFieldsList } from './SpecFieldsList';
import { SpecVarietiesCard } from './SpecVarietiesCard';

const SpecPage = ({ spec, varieties, fields, knownFields }: SpecPageProps) => {
  return (
    <div>
      <LayoutHeader isSticky>
        <div className="mb-8">
          <Breadcrumbs backLink={{ type: 'back' }}/>
        </div>
        <div className="flex items-center gap-4">
          <div>
            <ProduceImage produce={spec.produce} className="w-12 h-12"/>
          </div>
          <div>
            <div className="flex gap-4 items-center">
              <PageTitle>
                {spec.name}
              </PageTitle>

              <div>
                <Label size="xs" theme="emerald">
                  <LabelIcon icon={ApprovedIcon} className="stroke-emerald-500"/>
                  Approved
                </Label>
              </div>
            </div>
            <div>
              <MutedText>
                <FormatProduceName produce={spec.produce}/>
              </MutedText>
            </div>
          </div>
        </div>
      </LayoutHeader>

      <LayoutBody>
        <div className="mt-4 space-y-16">
          <SectionLayout variant="1_3">
            <div>
              <WithSectionIcon icon={TransportTemperatureIcon}>
                <SectionTitle>
                  Transport temperature
                </SectionTitle>
              </WithSectionIcon>

              <SectionDescription>
                This is the temperature range that this produce should be transported at.
              </SectionDescription>
            </div>

            <div>
              <ProduceTempForm produce={spec.produce}/>
            </div>
          </SectionLayout>

          <hr/>

          <SectionLayout variant="1_3">
            <div>
              <WithSectionIcon icon={ProduceIcon}>
                <SectionTitle>
                  Varieties
                </SectionTitle>
              </WithSectionIcon>

              <SectionDescription>
                Varieties are the different types of this produce that you sell.
              </SectionDescription>
            </div>

            <div>
              <SpecVarietiesCard
                spec={spec}
                varieties={varieties}
              />
            </div>
          </SectionLayout>

          <hr/>

          <SectionLayout variant="1_3">
            <div>
              <WithSectionIcon icon={MeasurementFieldIcon}>
                <SectionTitle>
                  Fields
                </SectionTitle>
              </WithSectionIcon>

              <SectionDescription>
                Fields are the different attributes of this produce that you want to track.
              </SectionDescription>
            </div>

            <div>
              <SpecFieldsList
                supportedFields={knownFields}
                spec={spec}
                fields={fields}
              />
            </div>
          </SectionLayout>
        </div>
      </LayoutBody>
    </div>
  );
};

SpecPage.layout = (page: ReactNode) => <SettingsLayout>{page}</SettingsLayout>;

export default SpecPage;
