import { ExoticComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericPicker } from '@/Pickers/GenericPicker';
import { BaseAction } from '~/Actions/BaseAction';
import { useCommandAction } from '~/hooks/useCommandAction';
import { PickerItem } from '~/types/types';
import { formatPicker } from '~/utils/formatPicker';

interface CommandAttributePickerProps {
  action: typeof BaseAction;
  target?: any;
  attribute: string;
  options: PickerItem[];
  title: string;
  selected?: any;
  disabled?: boolean;
  isMulti?: boolean;
  emptyMessage?: string;
  placeholder?: string;
  defaultEmptyValue?: any;
}

/**
 * An attribute picker abstraction that uses a command to update the target.
 *
 * How to use it: use it to set a property on a target and instantly update it using the `action`.
 *
 * @param action
 * @param target
 * @param options
 * @param attribute
 * @param selected
 * @param title
 * @param disabled
 * @param isMulti
 * @param emptyMessage
 * @param placeholder
 * @param defaultEmptyValue
 * @constructor
 */
export const CommandAttributePicker = ({
  action,
  target,
  options,
  attribute,
  selected,
  title,
  disabled,
  isMulti,
  emptyMessage,
  placeholder,
  defaultEmptyValue = null,
}: CommandAttributePickerProps) => {
  const { t } = useTranslation();
  const [commandInstance, { loading }] = useCommandAction(action, target);

  const formattedOptions = formatPicker(options);

  const getSelected = () => {
    if (selected) {
      return selected;
    }

    return target[attribute] ?? null;
  };

  return (
    <div>
      <GenericPicker
        disabled={disabled || commandInstance.disabled()}
        icon={commandInstance.getIcon() as ExoticComponent}
        autoFocus
        title={title}
        emptyMessage={emptyMessage ?? t('no_options')}
        placeholder={placeholder ?? t('select_option')}
        options={formattedOptions}
        loading={loading}
        isMulti={isMulti}
        selected={getSelected()}
        onChange={async (selectedValue: any) => {
          await commandInstance.execute({ [attribute]: selectedValue ?? defaultEmptyValue });
        }}
        showClear={true}
      />
    </div>
  );
};
