import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { CardFooter } from '@/Card';
import { Container } from '@/Container';
import { RangeDatePicker } from '@/DatePicker/RangeDatePicker';
import * as DropdownMenu from '@/DropdownMenu';
import { PlaceholderBox } from '@/Fallback';
import { ArrivalSiteIcon, ButtonIcon, FilterIcon, IssueIcon, ProduceIcon, SellerIcon, SortIcon } from '@/Icon';
import { LayoutHeader } from '@/Layout';
import { WithNumericIndicator } from '@/NumericIndicator';
import { GenericPicker } from '@/Pickers/GenericPicker';
import { RegionPicker } from '@/Pickers/RegionPicker';
import { SearchBar } from '@/Searchbar';
import { Spinner } from '@/Spinner';
import { TableFooter } from '@/Table';
import { TableGroupHeader } from '@/Table/TableGroupHeader';
import TabFilter from '@/Tabs/TabFilter';
import { MutedText, PageDescription, PageTitle } from '@/Text';
import { GenericFilterPane } from '~/Components/Panes/Filters/GenericFilterPane';
import { useAction } from '~/hooks/useAction';
import { useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { useQueryParamsFromStorage } from '~/hooks/useQueryParamsFromStorage';
import { OrdersPageProps } from '~/types/page-props';
import { Issue, Order, OrderStatusType } from '~/types/types';
import { counterToItems, formatPicker } from '~/utils/formatPicker';

import { DashboardLayout } from '../DashboardLayout';
import { OrderCard } from './OrderCard';

const includeGroupToIssueTypes = (pickerItems: Record<string, any>[]) => {
  return pickerItems.map((item) => {
    let group = undefined;

    if (
      item.value === 'minor_major_defects' || item.value === 'out_of_measurement_spec'
    ) {
      group = 'supplier';
    }

    if (
      item.value === 'transport_delay' ||
      item.value === 'transport_temp_threshold' ||
      item.value === 'transport_light_threshold'
    ) {
      group = 'logistics';
    }

    return {
      ...item,
      title: item.title,
      group,
    };
  });
};

interface GroupedRowsProps {
  groupedOrders: Record<string, Order[]>;
  label: string;
  sortType?: string;
  isLoading?: boolean;
  activeIssuesInfo?: Issue;
}

const GroupedRows = ({
  groupedOrders,
  label,
  sortType,
  isLoading,
}: GroupedRowsProps) => {
  const { t } = useTranslation();

  if (!(label in groupedOrders)) {
    return null;
  }

  const orders = groupedOrders[label];

  return (
    <>
      <TableGroupHeader className="my-4 !bg-gray-100 border-x !border-x-gray-200 rounded-lg">
        {t(label)}{' '}
        {sortType && (
          <MutedText>({t(`sort_by_describers.${sortType}`)})</MutedText>
        )}
      </TableGroupHeader>
      <div className="space-y-2">
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} isLoading={isLoading}/>
        ))}
      </div>
    </>
  );
};

interface FilterParameters {
  search?: string;
  sellerRegions?: string;
  sellerIds?: string[] | null;
  produceIds?: string[] | null;
  singleStatusType?: OrderStatusType;
  issueStatuses?: string[];
  active_filter_count?: number;
  sortType?: string;
  sort_by?: string;
  destinationSites?: string[];
  storeFilter?: number;
  resetPagination?: number;
  arrival_from?: Date | null | undefined;
  arrival_to?: Date | null | undefined;
  ship_from?: string | null | undefined;
  ship_to?: string | null | undefined;
  arrivalDate?: {
    from: Date | null | undefined;
    to: Date | null | undefined;
  };
  shipDates?: {
    from: string | null | undefined;
    to: string | null | undefined;
  };
}

const OrdersPage = ({
  data,
  ...props
}: OrdersPageProps) => {
  const { t } = useTranslation();
  const { reload, loading } = useAction<FilterParameters>('', {
    withParams: true,
    preserveState: true,
  });
  const { parse } = useDate();

  const { resetCurrentPage: refetchParameters, loading: fetchingParams } = useAction('', {
    preserveState: true,
    withParams: true,
    only: ['orders_per_arrival_date', 'orders_per_ship_date'],
  });

  const { clearStorageParams } = useQueryParamsFromStorage('order_filters');

  const { filterOptions, parameters, data: { groupedOrders } } = usePageProps<OrdersPageProps>();

  const formatDateTuples = (dates: { date: string, count: number }[]) => {

    if (!dates) return [];

    return dates.map(({ date, count }) => {
      return [parse(date) as Date, count];
    });
  };

  const arrivalDates = formatDateTuples(props?.orders_per_arrival_date);
  const shipDates = formatDateTuples(props?.orders_per_ship_date);

  const defaultFilterValues: FilterParameters = {
    search: parameters.search,
    sellerRegions: parameters.sellerRegions?.[0],
    sellerIds: parameters.sellerIds,
    produceIds: parameters.produceIds,
    destinationSites: parameters.destinationSites,
    issueStatuses: parameters.issueStatuses,
    storeFilter: parameters.storeFilter,
    arrivalDate: {
      from: parameters.fromArrivalDate ? parse(parameters.fromArrivalDate) : undefined,
      to: parameters.toArrivalDate ? parse(parameters.toArrivalDate) : undefined,
    },
    shipDates: {
      from: parameters.fromShipDate,
      to: parameters.toShipDate,
    },
  };

  const handleSortChange = (sortType: string) => {
    reload({
      sortType: sortType,
    });
  };

  const reloadFilters = (values: FilterParameters) => {
    reload({
      ...values,
      arrival_from: values.arrivalDate?.from,
      arrival_to: values.arrivalDate?.to,
      ship_from: values.shipDates?.from,
      ship_to: values.shipDates?.to,
      storeFilter: values.storeFilter ? 1 : 0,
      resetPagination: 1,
    });
  };

  const clearFilters = () => {
    reload({
      search: '',
      sellerRegions: '',
      sellerIds: null,
      produceIds: [],
      destinationSites: [],
      issueStatuses: [],
      storeFilter: 0,
    });
  };

  const tabFilterOptions = {
    items: [
      { label: 'All', number: data.order_count_per_status['all'] },
      { label: 'Open Issues', number: data.order_count_per_status['has_open_issues'], brand: 'blue' },
      { label: 'Accepted Issues', number: data.order_count_per_status['has_accepted_issues'], brand: 'orange' },
      { label: 'Escalated Issues', number: data.order_count_per_status['has_escalated_issues'], brand: 'red' },
    ],
    onChange: (index: number) => {
      reloadFilters({
        ...defaultFilterValues,
        singleStatusType: index === 0 ? 'all' :
          index === 1 ? 'has_open_issues' :
            index === 2 ? 'has_accepted_issues' :
              index === 3 ? 'has_escalated_issues' : 'all',
      });
    },
    activeIndex: parameters.singleStatusType === 'has_open_issues' ? 1 :
      parameters.singleStatusType === 'has_accepted_issues' ? 2 :
        parameters.singleStatusType === 'has_escalated_issues' ? 3 : 0,
  } as any;

  // Loop over the order statuses and map them to picker options;
  // => status: 'open', count: 10 => { label: 'Open', value: 'open' }

  const orderStatuses = includeGroupToIssueTypes(formatPicker(counterToItems(data.order_count_per_issue_type ?? {}).map(item => {
    return {
      ...item,
      title: t(`issue_status.${item.title}`),
    };
  })));

  return (
    <div>
      <LayoutHeader>
        <PageTitle>Orders</PageTitle>
        <PageDescription>Create & manage orders</PageDescription>

        <div className="mt-4 flex flex-wrap items-center justify-between gap-x-4 border-t pt-6 gap-y-2">
          <div className="flex gap-4 items-center">
            <TabFilter
              onChange={tabFilterOptions.onChange}
              items={tabFilterOptions.items}
              initialIndex={tabFilterOptions.activeIndex}
            />
            <div>
              {loading && (
                <Spinner/>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <div className="flex items-center gap-2">
              <div className="flex items-center">
                <DropdownMenu.DropdownRoot>
                  <DropdownMenu.DropdownTrigger className="h-full" asChild>
                    <Button variant="gray">
                      <ButtonIcon icon={SortIcon}/>
                      <span className="ml-2">
                        {t('sort_by')}
                      </span>
                    </Button>
                  </DropdownMenu.DropdownTrigger>
                  <DropdownMenu.DropdownContent>
                    <DropdownMenu.DropdownLabel>
                      {t('sort_by')}
                    </DropdownMenu.DropdownLabel>
                    <DropdownMenu.DropdownGroup>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('last_updated')}
                        checked={parameters.sort_by === 'last_updated' || !parameters.sort_by}>
                        {t('latest_update')}
                      </DropdownMenu.DropdownCheckboxItem>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('shipping_date')}
                        checked={parameters.sort_by === 'shipping_date'}>
                        {t('shipping_date')}
                      </DropdownMenu.DropdownCheckboxItem>
                      <DropdownMenu.DropdownCheckboxItem onSelect={() => handleSortChange('arrival_date')}
                        checked={parameters.sort_by === 'arrival_date'}
                      >
                        {t('arrival_date')}
                      </DropdownMenu.DropdownCheckboxItem>
                    </DropdownMenu.DropdownGroup>
                  </DropdownMenu.DropdownContent>
                </DropdownMenu.DropdownRoot>
              </div>

              <GenericFilterPane
                title="Filters"
                onClear={clearFilters}
                defaultValues={defaultFilterValues}
                onChange={reloadFilters}
                usesStoreFilter
                filters={
                  [
                    {
                      label: 'General Search',
                      description: 'You can search by PO, produce, variety, name of the seller, and events such as "Load uploaded".',
                      group: 'top_search',
                      fieldKey: 'search',
                      component: (
                        <SearchBar
                          className="min-w-[300px] w-full"
                          placeholder={t('orders_page_search') as string}
                          loading={loading}
                        />
                      ),
                    },
                    {
                      label: 'Issue status',
                      description: 'Filter by orders with open issues',
                      group: 'general',
                      fieldKey: 'issueStatuses',
                      component: (
                        <GenericPicker
                          icon={IssueIcon}
                          groups={{
                            supplier: { label: 'Supplier input', icon: ProduceIcon },
                            logistics: { label: 'Logistics', icon: ProduceIcon },
                          }}
                          options={orderStatuses as any}
                          title="Issue status"
                          isMulti
                          placeholder="Select issue status"
                          emptyMessage="No issue statuses found"
                        />
                      ),
                    },
                    {
                      label: 'Regions',
                      description: 'Select the region where the produce is sourced',
                      group: 'origin',
                      fieldKey: 'sellerRegions',
                      component: (
                        <RegionPicker
                          onChange={() => {
                            return;
                          }}
                          regions={filterOptions.regions}
                          titleKey="Region"
                        />
                      ),
                    },
                    {
                      label: 'Shipping date',
                      description: 'Select the shipping date of the order',
                      fieldKey: 'shipDates',
                      group: 'origin',
                      component: (
                        <RangeDatePicker
                          dateNumbers={shipDates as [Date, number][]}
                          dateNumbersLabel="Orders per ship date"
                          loading={fetchingParams}
                          onOpen={refetchParameters}
                        />
                      ),
                    },
                    {
                      label: 'Sellers',
                      description: 'Select a seller to filter by',
                      fieldKey: 'sellerIds',
                      group: 'origin',
                      component: (
                        <GenericPicker
                          icon={SellerIcon}
                          options={formatPicker(filterOptions.suppliers, 'id', 'title')}
                          isMulti
                          title="Seller"
                          placeholder="Select sellers"
                          emptyMessage="No sellers found"
                        />
                      ),
                    },
                    {
                      label: 'Produces',
                      description: 'Select produce to filter by',
                      fieldKey: 'produceIds',
                      group: 'produce',
                      component: (
                        <GenericPicker
                          icon={ProduceIcon}
                          options={formatPicker(filterOptions.produces, 'id', 'name')}
                          isMulti
                          title="Produce"
                          placeholder="Search produce..."
                          emptyMessage="No produce found"
                        />
                      ),
                    },
                    {
                      label: 'Arrival site',
                      description: 'Select site by where the produce is expected to arrive',
                      fieldKey: 'destinationSites',
                      group: 'arrival',
                      component: (
                        <GenericPicker
                          icon={ArrivalSiteIcon}
                          options={formatPicker(filterOptions.delivery_locations ?? [])}
                          isMulti
                          title="Arrival site"
                          placeholder="Search arrival sites..."
                          emptyMessage="No arrival sites found"
                        />
                      ),
                    },
                    {
                      label: 'Arrival date',
                      description: 'Select the arrival date of the produce',
                      fieldKey: 'arrivalDate',
                      group: 'arrival',
                      component: (
                        <RangeDatePicker
                          dateNumbers={arrivalDates as [Date, number][]}
                          dateNumbersLabel="Orders per arrival date"
                          loading={fetchingParams}
                          onOpen={refetchParameters}
                        />
                      ),
                    },
                  ]
                }
              >
                <div>
                  <WithNumericIndicator label={`number active filters are ${parameters.active_filter_count}`}
                    value={parameters.active_filter_count ?? 0} brand="blue" size="xs">
                    <Button variant="gray">
                      <ButtonIcon icon={FilterIcon}/>
                      <span className="ml-2">
                        {t('filter')}
                      </span>
                    </Button>
                  </WithNumericIndicator>
                </div>
              </GenericFilterPane>
            </div>
          </div>
        </div>
      </LayoutHeader>

      {data.orders.data.length === 0 && (
        <Container>
          <PlaceholderBox
            icon={<ProduceIcon className="w-12 h-12 stroke-gray-500"/>}
            title={t('placeholders.no_orders_available')} description={t('placeholders.no_orders_buyers_description')}
            loading={loading}
          >
            <Button onClick={() => clearFilters()} variant="white">
              {t('clear_filters')}
            </Button>
          </PlaceholderBox>
        </Container>
      )}

      {data.orders.data.length > 0 && (
        <Container size="xl">
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="today"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="tomorrow"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="yesterday"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="this_week"
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="next_week"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="this_month"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="next_month"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="future"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <GroupedRows
            isLoading={loading}
            sortType={parameters.sortType}
            groupedOrders={groupedOrders}
            label="older"
            activeIssuesInfo={props.active_orders_issue_data}
          />
          <CardFooter>
            <TableFooter paginatedItems={data.orders}/>
          </CardFooter>
        </Container>
      )}
    </div>
  );
};

OrdersPage.layout = (page: React.ReactNode) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default OrdersPage;
