import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithAwaitingFallback } from '@/Fallback';
import {
  TransportLocationIcon,
} from '@/Icon';
import Section, { SectionLayout } from '~/Components/Common/Section';
import { LogisticsIssuesCard } from '~/Components/Issues/Logistics/LogisticsIssuesCard';
import { usePageProps } from '~/hooks/usePageProps';
import { LocationDataPayload, OrderDataStream, OrderDataStreamType, TemperaturePayload } from '~/types/types';

import { MapDataStream } from '../MapDataStream';
import { getDataStreamIssues } from '../utils';
import { TemperatureSummarySection } from './TemperatureSummaryCard';

interface OrderLogisticsSection {
  dataStreams: OrderDataStream<LocationDataPayload | TemperaturePayload>[];
}

export const OrderLogisticsSection = ({ dataStreams }: OrderLogisticsSection) => {
  const { t } = useTranslation();

  const { data } = usePageProps();

  const transportIssue = data.transportIssue;

  const relevantDataStreams = dataStreams.filter((dataStream) => {
    return dataStream.type === OrderDataStreamType.LOCATION || dataStream.type === OrderDataStreamType.TRANSPORT_TEMPERATURE;
  });

  // Sum up issues of each data stream (using dataStream.issues.length for each data stream)

  if (relevantDataStreams.length === 0) {
    return null;
  }

  // Group data-streams-by-type
  const dataStreamsByType = relevantDataStreams.reduce((acc, dataStream) => {
    if (!acc[dataStream.type]) {
      acc[dataStream.type] = [];
    }

    acc[dataStream.type].push(dataStream);

    return acc;
  }, {} as Record<string, OrderDataStream<LocationDataPayload | TemperaturePayload>[]>);

  return (
    <Section title={t('logistics')} icon={TransportLocationIcon}>
      <SectionLayout variant="3_1">
        <div>
          <WithAwaitingFallback>
            <div className="space-y-4">
              {Object.entries(dataStreamsByType).map(([type, dataStreams]) => (
                <div key={type}>
                  {type === OrderDataStreamType.TRANSPORT_TEMPERATURE && (
                    <TemperatureSummarySection dataStreams={dataStreams}/>
                  )}

                  {type === OrderDataStreamType.LOCATION && (
                    <MapDataStream dataStream={dataStreams[0]}/>
                  )}

                  {type === OrderDataStreamType.WEATHER && (
                    <MapDataStream dataStream={dataStreams[0]}/>
                  )}
                </div>
              ))}
            </div>
          </WithAwaitingFallback>
        </div>

        <div>
          {transportIssue && (
            <LogisticsIssuesCard compoundIssue={transportIssue}/>
          )}
        </div>
      </SectionLayout>
    </Section>
  );
};
