import { Card, CardBody, CardHeaderContainer } from '@/Card';
import { MutedText } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { TooltipProps } from '~/types/global-types';
import { WeatherDataDayPayload } from '~/types/types';

type Payload = WeatherDataDayPayload;

type WeatherTemperatureTooltipProps = TooltipProps<Payload>;

export const WeatherTemperatureTooltip = ({
  payload,
  label,
}: WeatherTemperatureTooltipProps) => {
  const { format, parse } = useDate();

  const data = payload?.[0]?.payload;

  return (
    <Card className="min-w-[150px]">
      <CardHeaderContainer className="!py-4" size="sm">
        <MutedText>
          {format(parse(label, DateFormat.ISO), DateFormat.HumanMonthDay)}
        </MutedText>
      </CardHeaderContainer>
      <CardBody size="sm">
        {/* Render the min, max and average of the payload */}
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className="w-2 h-2 mr-2 rounded-full"
              style={{ backgroundColor: '#3B82F6' }}
            />
            <MutedText>Min</MutedText>
          </div>
          <div className="flex items-center">
            <MutedText>{data?.minCelcius}°C</MutedText>
          </div>
        </div>

        <hr />

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className="w-2 h-2 mr-2 rounded-full"
              style={{ backgroundColor: '#10B981' }}
            />
            <MutedText>Max</MutedText>
          </div>
          <div className="flex items-center">
            <MutedText>{data?.maxCelcius}°C</MutedText>
          </div>
        </div>

        <hr />

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className="w-2 h-2 mr-2 rounded-full"
              style={{ backgroundColor: '#F59E0B' }}
            />
            <MutedText>Average</MutedText>
          </div>
          <div className="flex items-center">
            <MutedText>{data?.avgCelcius?.toFixed(2)}°C</MutedText>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
