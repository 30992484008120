import { RemoveSpecField } from '~/Actions/Specs/RemoveSpecField';
import { SaveSpecField } from '~/Actions/Specs/SaveSpecField';
import { useConfirmationModal } from '~/Components/Common/Modals/useConfirmationModal';
import { useCommandAction } from '~/hooks/useCommandAction';
import { Field, Spec } from '~/types/types';

import { EditSpecFieldCardForm } from './EditSpecFieldCardForm';
import { PreviewSpecFieldCard } from './PreviewSpecFieldCard';

interface SpecFieldCardProps {
  spec: Spec;
  defaultField: Field;
  knownFields: Field[];
  isEdit: boolean;
  saveAction: SaveSpecField;
  onEnableEdit: () => void;
  onCancel: () => void;
  disabled?: boolean;

}

export const SpecFieldCard = ({
  isEdit,
  defaultField,
  onEnableEdit,
  disabled,
  spec,
  knownFields,
  saveAction,
  onCancel,
}: SpecFieldCardProps) => {
  const [deleteField, { loading }] = useCommandAction(RemoveSpecField, spec);

  const { confirm, ConfirmationModal } = useConfirmationModal({
    title: 'Delete field',
    description: 'Are you sure you want to delete this field? This action cannot be undone.',
    confirmText: 'Delete',
    cancelText: 'Cancel',
  });

  const handleDelete = () => {
    if (!deleteField.disabled()) {
      confirm(async () => {
        await deleteField.execute(defaultField);
      });
    }
  };

  return (
    <div>
      {!isEdit ? (
        <PreviewSpecFieldCard
          onEnableEdit={onEnableEdit}
          field={defaultField}
          disabled={disabled}
          onRemove={handleDelete}
        />
      ) : (
        <EditSpecFieldCardForm
          spec={spec}
          onCancel={onCancel}
          saveAction={saveAction}
          defaultField={defaultField}
          knownFields={knownFields}
          disabled={loading}
          onRemove={handleDelete}
        />
      )}

      <ConfirmationModal/>
    </div>
  );
};
