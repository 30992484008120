import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';

import { Button } from '@/Button';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalTitle } from '@/Modal';

interface UseConfirmationModalProps {
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
}

export const useConfirmationModal = ({
  title = 'Are you sure?',
  description = 'This action cannot be undone.',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}: UseConfirmationModalProps = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [callback, setCallback] = useState<(() => void) | null>(null);

  const ConfirmationModal = useCallback(() => {
    return (
      <Modal open={isOpen} onOpenChange={setIsOpen}>
        <ModalContent>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{
              type: 'spring',
              duration: 0.3,
              bounce: 0.15,
            }}
          >
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <div className="flex flex-col space-y-4">
                <p className="text-sm text-zinc-600 dark:text-zinc-300">
                  {description}
                </p>

                <div className="flex justify-end space-x-3">
                  <Button
                    variant="outline"
                    onClick={() => setIsOpen(false)}
                  >
                    {cancelText}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (callback) {
                        callback();
                      }
                      setIsOpen(false);
                    }}
                  >
                    {confirmText}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </motion.div>
        </ModalContent>
      </Modal>
    );
  }, [isOpen, callback, title, description, confirmText, cancelText]);

  const confirm = useCallback((cb: () => void) => {
    setCallback(() => cb);
    setIsOpen(true);
  }, []);

  return {
    confirm,
    ConfirmationModal,
  };
};
