"use client";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "../utils/cn";
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "./Popover";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipHelper = ({ children }: { children: React.ReactNode }) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger>
        <QuestionMarkCircleIcon
          className="w-4 h-4 text-gray-400"
          aria-hidden="true"
        />
      </TooltipTrigger>
      <TooltipContent sideOffset={5} alignOffset={5}>
        {children}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const Tooltipped = ({
  children,
  label,
  duration = 500,
}: {
  children: React.ReactNode;
  label: React.ReactNode;
  duration?: number;
}) => (
  <TooltipProvider delayDuration={duration}>
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent collisionPadding={5} sideOffset={5} alignOffset={5}>
        {label}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const ResponsiveTooltip = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="hidden md:revert">
        <TooltipHelper>{children}</TooltipHelper>
      </div>
      <div className="md:hidden">
        <Popover>
          <PopoverTrigger>
            <QuestionMarkCircleIcon
              className="w-4 h-4 text-gray-400"
              aria-hidden="true"
            />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent
              sideOffset={5}
              alignOffset={5}
              className={cn(
                "z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
              )}
            >
              {children}
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </div>
    </>
  );
};

export {
  ResponsiveTooltip,
  Tooltip,
  TooltipContent,
  TooltipHelper,
  TooltipProvider,
  TooltipTrigger,
};
