/* eslint-disable react/prop-types */
import { type VariantProps, cva } from 'class-variance-authority';
import { ThumbsUp } from 'lucide-react';
import * as React from 'react';

import { AIIcon, CloseIcon, WarningIcon } from '@/Icon';
import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

const alertVariants = cva(
  'relative w-full rounded-lg border px-6 py-4 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*]:pl-7',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        ai: 'bg-slate-100 border-slate text-slate-500 dark:text-slate-300',
        good: 'bg-emerald-50 border-emerald text-emerald-800 dark:text-emerald-300',
        destructive:
          'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
        warning: 'bg-yellow-50 border-none text-yellow-800 dark:text-yellow-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const alertIconVariants = cva(
  'w-5 h-5 text-current *:w-5 *:h-5',
  {
    variants: {
      variant: {
        default: 'text-slate-400',
        good: 'text-emerald-700 dark:text-emerald-300',
        destructive: 'text-destructive',
        ai: 'text-blue-500 dark:text-blue-300',
        warning: 'text-yellow-500 dark:text-yellow-300',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants> & {
  onDismiss?: () => void;
    }
    >(({ className, variant, children, onDismiss, ...props }, ref) => (
      <div
        ref={ref}
        role="alert"
        className={cn(alertVariants({ variant }), 'relative', className)}
        {...props}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <div className={alertIconVariants({ variant })}>
              {variant === 'warning' && <WarningIcon/>}
              {variant === 'ai' && <AIIcon/>}
              {variant === 'good' && <ThumbsUp/>}
            </div>
          </div>
          <div className="ml-3">{children}</div>
        </div>
        {onDismiss && <AlertDismiss onClick={onDismiss}/>}
      </div>
    ));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn('mb-2 font-medium tracking-tight', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('text-sm [&_p]:leading-relaxed font-light', className)}
    {...props}
  />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertDescription, AlertTitle };

export const WarningBadge = () => (
  <div className="text-yellow-600">
    <WarningIcon/>
  </div>
);

export const AlertFooter = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-2 border-t pt-2">
    {children}
  </div>
);

const AlertDismiss = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => (
  <Tooltipped label="Dismiss">
    <button
      ref={ref}
      className={cn(
        'absolute top-2 right-2 rounded-md p-1 text-foreground/50 opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-background',
        className
      )}
      {...props}
    >
      <CloseIcon className="h-4 w-4"/>
      <span className="sr-only">Close</span>
    </button>
  </Tooltipped>
));
AlertDismiss.displayName = 'AlertDismiss';
