import { JsonEditor } from 'json-edit-react';
import { cloneElement, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { DebugJSON } from '@/JSON';
import { LabeledField } from '@/LabeledField';
import { Pane, PaneContent, PaneFooter, PaneHeader, PanePortal, PaneTitle, PaneTrigger } from '@/Pane';
import { Spinner } from '@/Spinner';
import { DateFormat, useDate } from '~/hooks/useDate';

export interface DetailConfig {
  title: string;
  description?: string;
  key: string;
  dtype?: string;
  group?: string;
  component?: ReactElement;
}

interface GenericDetailsPaneProps {
  object: Record<string, any>;
  fetchData?: () => any; // New prop for the callback function
  details: DetailConfig[];
  title?: string;
  isDefaultOpen?: boolean;
  container?: HTMLElement;
  children?: ReactNode;
  loading?: boolean;
}

/**
 * The GenericDetailsPane is a Pane which renders a list of details of an object.
 *
 * How to define a detail-config:
 * - Each detail is defined by a `DetailConfig` object. This contains a `title` and `description` for display
 * and `key`, which is the key of the object to fetch the value from. Optionally, a `component` can be passed to render the value.
 * - `dtype` can be used to render details in different ways based on the type.
 * - `group` can be used to group details together.
 *
 * How to use the GenericDetailsPane:
 * - The GenericDetailsPane can receive `object` which is the object containing the details.
 * - The GenericDetailsPane can receive a `title` which is the title of the Pane.
 */
export const GenericDetailsPane = ({
  object,
  details,
  title,
  children,
  isDefaultOpen,
  container,
  fetchData,
  loading,
}: GenericDetailsPaneProps) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const { t } = useTranslation();
  const { parseOrFormat } = useDate();

  const groupedDetails = details.reduce((acc, detail) => {
    const group = detail.group || 'default';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(detail);
    return acc;
  }, {} as Record<string, DetailConfig[]>);

  const hasFetched = useRef(false); // Track if fetch has been executed

  useEffect(() => {
    if (isOpen && fetchData && !hasFetched.current) {
      fetchData();
      hasFetched.current = true;
    }
  }, [isOpen, fetchData]);

  const renderDetailValue = (detail: DetailConfig) => {
    if (loading) return null;
    if (!object[detail.key]) return null;

    switch (detail.dtype) {
    case 'date':
      return parseOrFormat(object[detail.key], DateFormat.DayFormat);
    case 'number':
      return object[detail.key];
    case 'code':
      return <pre>
        {object[detail.key]}
      </pre>;
    case 'currency':
      return `$${object[detail.key].toFixed(2)}`;
    case 'custom':
      return detail.component ? cloneElement(detail.component, { value: object[detail.key] }) : object[detail.key];
    case 'json': {
      const data = object[detail.key];

      // Function to count all nodes in a nested JSON object
      const countNodes = (obj: any): number => {
        if (Array.isArray(obj)) {
          return obj.reduce((sum, item) => sum + countNodes(item), 0);
        } else if (typeof obj === 'object' && obj !== null) {
          return Object.values(obj).reduce((sum, value) => sum + countNodes(value), 0 as number) ?? 0;
        }
        return 1;
      };

      const totalNodes = countNodes(data);

      // If the total number of nodes is huge, we use a "native" DebugJSON component
      // else, we use the JsonEditor
      if (totalNodes > 1000) {
        return <DebugJSON json={data}/>;
      }
      return cloneElement(<JsonEditor data={data}/>);
    }
    default:
      return object[detail.key];
    }
  };

  return (
    <div>
      <Pane open={isOpen} onOpenChange={setIsOpen}>
        {!!children && (
          <PaneTrigger asChild className="h-6">{children}</PaneTrigger>
        )}

        <PanePortal container={container}>
          <PaneContent className="flex flex-col gap-4 p-4 overflow-scroll">
            <PaneHeader className="flex flex-row items-center space-y-0">
              <PaneTitle className="py-4">
                {title ? (
                  title
                ) : (
                  t('details')
                )}
              </PaneTitle>
            </PaneHeader>

            {loading && (
              <Spinner/>
            )}
            <div className="flex flex-col space-y-4">
              {Object.keys(groupedDetails).map((group, groupIndex) => (
                <div key={groupIndex} className="flex flex-col space-y-4">
                  {groupedDetails[group].map((detail, index) => {
                    const detailValue = renderDetailValue(detail);
                    if (!detailValue) return null;

                    return (
                      <div key={index} className="flex flex-col">
                        <LabeledField
                          label={detail.title}
                          subLabel={detail.description}
                          value={renderDetailValue(detail)}
                        />
                      </div>
                    );
                  })}
                  {groupIndex < Object.keys(groupedDetails).length - 1 && <hr/>}
                </div>
              ))}
            </div>
            <PaneFooter>
              <div className="flex space-x-2">
                <Button onClick={() => setIsOpen(false)}>
                  {t('close')}
                </Button>
              </div>
            </PaneFooter>
          </PaneContent>
        </PanePortal>
      </Pane>
    </div>
  );
};
