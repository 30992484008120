import { LightBulbIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardFooter, SimpleCardHeader } from '@/Card';
import { WithAwaitingFallback } from '@/Fallback';
import { AIIcon, Icon, IconBox, IconButtonIcon, IssueIcon, SellerIcon, SimpleCardHeaderIcon, UmbrellaIcon } from '@/Icon';
import { CardTitle, Helper, MutedText, SectionTitle, SimpleCardTitle, Strong, Text } from '@/Text';
import Section, { SectionLayout } from '~/Components/Common/Section';
import { IssueActions } from '~/Components/Orders/IssueActions';
import { OrderDataStream, OrderDataStreamType, WeatherDataPayload } from '~/types/types';

import { QualityLabel } from '../../QualityLabel';
import { MapDataStream } from '../MapDataStream';
import { calculateGenericIssuesCount } from '../utils';

export interface HarvestSectionProps {
  dataStreams: OrderDataStream<WeatherDataPayload>[];
}

export const HarvestSection = ({ dataStreams }: HarvestSectionProps) => {
  const { t } = useTranslation();

  const relevantDataStreams = dataStreams.filter((dataStream) => {
    return dataStream.type === OrderDataStreamType.WEATHER;
  });

  const issuesCount = useMemo(() => calculateGenericIssuesCount(relevantDataStreams), [relevantDataStreams]);

  if (relevantDataStreams.length === 0) {
    return null;
  }

  return (
    <div>
      <Section title={t('weather')} icon={SellerIcon} showCollapsed enableToggle>
        <SectionLayout variant="3_1">
          <WithAwaitingFallback>
            <div className="space-y-4">
              {relevantDataStreams.map((dataStream, index) => (
                <MapDataStream key={index} dataStream={dataStream}/>
              ))}
            </div>
          </WithAwaitingFallback>
          <div>
            <Card>
              <SimpleCardHeader className="!bg-rose-600 rounded-t">
                <SimpleCardHeaderIcon className="!text-rose-50" icon={IssueIcon}/>
                <SimpleCardTitle className="!text-rose-50">
                  Weather issue
                </SimpleCardTitle>
              </SimpleCardHeader>
              <CardBody>
                <div className="mb-3">
                  <div className="mb-2">
                    <Helper>Explanation</Helper>
                  </div>
                  <div className="flex gap-4">
                    <IconBox className="!bg-rose-50 !text-rose-700 w-8 h-8 flex items-center justify-center !border-0">
                      <IconButtonIcon className="!text-rose-700 !max-w-6" icon={UmbrellaIcon}/>
                    </IconBox>

                    <div>
                      <Text>
                        In the growing period, there was reduced rainfall.
                      </Text>
                      <div className="bg-gray-50 border-2 px-2 py-2 my-2 rounded">
                        <MutedText>
                          <Strong className="text-xs flex !text-slate-800">
                            Context
                          </Strong>

                          <div>
                            Historic trend data is associated with risk of <Strong className="!text-xs !text-gray-500">salt
                            injury</Strong>.
                          </div>
                        </MutedText>

                        <div className="mt-1">
                          <Button variant="white" size="xs">
                            Show more
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <hr className="py-1"/>

                <div>
                  <div className="mb-2">
                    <Helper>Predicted impact</Helper>
                  </div>

                  <div className="flex gap-4">
                    <IconBox className="!bg-blue-50 !text-blue-700 w-8 h-8 flex items-center justify-center !border-0">
                      <IconButtonIcon className="!text-blue-700 !max-w-6" icon={AIIcon}/>
                    </IconBox>

                    <div>
                      <Text>
                        The forecasted yield for this produce is forecasted to be <Strong className="!text-rose-700">critically
                        low</Strong>.
                      </Text>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <IssueActions
                  loading={false}
                  onClick={() => {
                  }}
                  issue={{ status: '', comment: '', informSeller: false }}
                  onChange={() => {
                  }}
                />
              </CardFooter>
            </Card>
          </div>
        </SectionLayout>
      </Section>
    </div>
  );
};
