import { ClipboardIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { JsonEditor } from 'json-edit-react';
import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { ActionbarItem, ActionbarRoot } from '@/Actionbar';
import { ActionbarIcon } from '@/Icon';
import { AnimatedFinishedLabel } from '@/Label';
import { LocalOnly } from '@/LocalOnly';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalTitle, ModalTrigger } from '@/Modal';

import Section from './Section';

// Context for managing debug data
interface DebugContextType {
  pageProps: any;
  lastPostPayload: any;
  setLastPostPayload: (payload: any) => void;
}

const DebugContext = createContext<DebugContextType | undefined>(undefined);

export const DebugProvider: React.FC<{ children: ReactNode; pageProps: any }> = ({ children, pageProps }) => {
  const [lastPostPayload, setLastPostPayload] = useState<any>(null);

  return (
    <DebugContext.Provider value={{ pageProps, lastPostPayload, setLastPostPayload }}>
      {children}
    </DebugContext.Provider>
  );
};

export const useDebug = () => {
  const context = useContext(DebugContext);
  if (!context) {
    throw new Error('useDebug must be used within a DebugProvider');
  }
  return context;
};

const countNodes = (obj: any): number => {
  if (Array.isArray(obj)) {
    return obj.reduce((sum, item) => sum + countNodes(item), 0);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.values(obj).reduce((sum, value) => sum + countNodes(value), 0 as number) ?? 0;
  }
  return 1;
};

const DebugJSON = ({ json }: { json: any }) => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg">
      <pre className="text-xs">{JSON.stringify(json, null, 2)}</pre>
    </div>
  );
};

export const LocalDebugModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCopiedLabel, setShowCopiedLabel] = useState(false);
  const [showLastPayload, setShowLastPayload] = useState(false);
  const { pageProps, lastPostPayload } = useDebug();

  const handleCopy = useCallback((json: any) => {
    navigator.clipboard.writeText(JSON.stringify(json, null, 2))
      .then(() => {
        console.log('JSON copied to clipboard');
        setShowCopiedLabel(true);
        setTimeout(() => setShowCopiedLabel(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy JSON:', err);
      });
  }, []);

  const renderJSONSection = (title: string, json: any) => {
    const totalNodes = countNodes(json);
    const useJsonEditor = totalNodes <= 1000;

    return (
      <Section
        title={title}
        enableToggle
        defaultOpen={true}
      >
        {useJsonEditor ? (
          <JsonEditor data={json}/>
        ) : (
          <DebugJSON json={json}/>
        )}
      </Section>
    );
  };

  return (
    <LocalOnly>
      <Modal open={isOpen} onOpenChange={setIsOpen}>
        <ModalTrigger asChild>
          <div className="fixed !w-12 z-100 bottom-4 left-4">
            <button
              type="button"
              className="flex items-center justify-center w-12 h-12 text-white bg-gray-900 rounded-full focus:outline-none"
              onClick={() => setIsOpen(true)}
            >
              <MagnifyingGlassIcon className="h-7 w-7"/>
            </button>
          </div>
        </ModalTrigger>
        <ModalContent className="max-w-7xl h-[90vh] flex flex-col">
          <div className="absolute bottom-0 max-w-lg right-0 z-10">
            <div>
              <ActionbarRoot show={isOpen}>
                <AnimatedFinishedLabel show={showCopiedLabel}/>
                <ActionbarItem className="w-full" onClick={() => handleCopy(pageProps)} shortcut="⌘C">
                  <ActionbarIcon icon={ClipboardIcon}/>
                  Copy Page Props
                </ActionbarItem>
                {lastPostPayload && (
                  <ActionbarItem className="w-full" onClick={() => handleCopy(lastPostPayload)} shortcut="⌘⇧C">
                    <ActionbarIcon icon={ClipboardIcon}/>
                    Copy Last POST Payload
                  </ActionbarItem>
                )}
              </ActionbarRoot>
            </div>
          </div>
          <ModalBody className="flex-grow overflow-auto">
            <div className="space-y-4">
              {renderJSONSection('Page Props', pageProps)}
              {renderJSONSection('Payload', lastPostPayload)}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </LocalOnly>
  );
};

// Hook to update the last POST payload
export const useUpdateLastPostPayload = () => {
  const { setLastPostPayload } = useDebug();

  return useCallback((payload: any) => {
    setLastPostPayload(payload);
  }, [setLastPostPayload]);
};
