import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardFooter, CardGutter, CardHeaderContainer } from '@/Card';
import { HarvestIcon, IssueIcon, MeasurementFieldIcon, QualityIssuesIcon, SimpleCardHeaderIcon } from '@/Icon';
import { CardDescription, CardTitle, MutedText } from '@/Text';
import {
  extractUniqueXLabels,
  extractUniqueYLabels,
  formatCorrelationData,
} from '~/Components/Analytics/Charts/Correlations/correlation-utils';

import CorrelationGraph from './CorrelationGraph';
import Correlation = App.Domain.Analytics.Correlations.Correlation;

import { Recommendation, RecommendationPrompt } from '~/Components/AI/Recommendations/RecommendationPrompt';
import QualityIssuesCorrelationType = App.Domain.Reports.Modules.QualityIssues.QualityIssuesCorrelationType;
import { GenericPicker, OptionBaseType } from '@/Pickers/GenericPicker';

interface QualityCorrelationGraphProps {
  title?: string;
  description?: string;
  theme?: 'red' | 'green';
  correlations: Correlation;
  xLabel?: string;
  recommendation?: Recommendation;
  correlationType?: QualityIssuesCorrelationType;
}

export const colorMapBrands = {
  'red': {
    'Null': 'bg-white border-gray-200 border',
    'Very Weak': 'bg-rose-200 border-rose-200 border',
    'Weak': 'bg-rose-400',
    'Strong': 'bg-rose-600',
    'Very Strong': 'bg-rose-800',
  },
  'green': {
    'Null': 'bg-white border-gray-200 border',
    'Very Weak': 'bg-green-200 border-green-200 border',
    'Weak': 'bg-green-400',
    'Strong': 'bg-green-600',
    'Very Strong': 'bg-green-800',
  },
};

const correlationOptionTypes: OptionBaseType[] = [
  {
    value: 'issue_to_issue',
    label: 'Issue to Issue',
    icon: IssueIcon,
  },
  {
    value: 'field_to_issue',
    label: 'Field to Issue',
    icon: MeasurementFieldIcon,
  },
  {
    value: 'harvest_to_field',
    label: 'Harvest to Field',
    icon: HarvestIcon,
  },
];

export const QualityCorrelationGraph = ({
  title,
  description,
  theme = 'red',
  correlations,
  xLabel = 'issue',
  recommendation,
  correlationType,
}: QualityCorrelationGraphProps) => {
  const { t } = useTranslation();

  const chartData = formatCorrelationData(correlations);

  const xLabels = extractUniqueXLabels(chartData, xLabel);
  const yLabels = extractUniqueYLabels(chartData, xLabel);

  const colorMap = colorMapBrands[theme];

  return (
    <div data-testid="correlation-graph">
      <Card>
        <CardHeaderContainer size="sm">
          <div className="flex items-center justify-between gap-2">
            <div>
              <SimpleCardHeaderIcon icon={QualityIssuesIcon}/>
              <MutedText>
                {t('reports.quality_issues')}
              </MutedText>
            </div>
          </div>
        </CardHeaderContainer>
        <CardBody bordered="y">
          <CardTitle>
            {title}
          </CardTitle>
          <CardDescription>
            {description}
          </CardDescription>
        </CardBody>
        <CardGutter>
          <GenericPicker
            title={t('correlation_type')}
            options={correlationOptionTypes}
            value={correlationType}
            showClear={false}
            emptyMessage=""
            placeholder=""
            disableSearch
            disabled
          />
        </CardGutter>
        <CardBody size="md">
          <CorrelationGraph
            data={chartData}
            xLabels={xLabels}
            yLabels={yLabels}
            colorMap={colorMap}
            xAxisLabel={xLabel}
            yAxisLabel="Categories"
          />
        </CardBody>

        {!!recommendation && (
          <CardFooter variant="shadowed">
            <RecommendationPrompt recommendation={recommendation}/>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};
