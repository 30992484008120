import { ExclamationCircleIcon, MapPinIcon } from '@heroicons/react/20/solid';
import { viewport } from '@placemarkio/geo-viewport';
import { ErrorBoundary } from '@sentry/react';
import turfBbox from '@turf/bbox';
import { featureCollection as turfFeatureCollection, point as turfPoint } from '@turf/helpers';
import { Map, Marker } from 'pigeon-maps';
import { useMemo } from 'react';

import { WithHoverCard } from '@/HoverCard';
import { LabeledField } from '@/LabeledField';
import { DateFormat, useDate } from '~/hooks/useDate';
import { useError } from '~/hooks/useError';
import { LocationDataPayload } from '~/types/types';

import { HarborIcon } from './HarborIcon';
import { ShipIcon } from './ShipIcon';

interface TransportMapProps {
  data: LocationDataPayload;
}

export const TransportMap = ({ data }: TransportMapProps
) => {
  const { log } = useError();
  const latestEvent = data.events.length > 0 ? data.events[data.events.length - 1] : null;

  const { parseOrFormat } = useDate();

  /**
   * All other events, except the latest one.
   */
  const otherEvents = data.events.length > 1 ? data.events.slice(0, data.events.length - 1) : [];
  const eventsWithIssues = data.events.filter((event) => event.issue !== undefined);

  if (!latestEvent) {
    return null;
  }

  /**
   * Calculate the center and zoom of the map based on the included coordinates.
   */
  const { center, zoom } = useMemo(() => {
    try {
      /**
       * The coordinates that are included in the map, are:
       * - The latest event
       * - The origin
       * - The destination
       */
      const includedCoordinates = [];

      if (latestEvent.lat && latestEvent.lon) {
        includedCoordinates.push([latestEvent.lon, latestEvent.lat]);
      }

      eventsWithIssues.forEach((event) => {
        if (event.lat && event.lon) {
          includedCoordinates.push([event.lon, event.lat]);
        }
      });

      if (data.meta.origin?.lat && data.meta.origin?.lon) {
        includedCoordinates.push([data.meta.origin.lon, data.meta.origin.lat]);
      }

      if (data.meta.destination?.lat && data.meta.destination?.lon) {
        includedCoordinates.push([data.meta.destination.lon, data.meta.destination.lat]);
      }

      const points = includedCoordinates.map((coordinates) => turfPoint(coordinates));
      const features = turfFeatureCollection(points);
      const bounds = turfBbox(features);
      // @ts-ignore
      const { center, zoom } = viewport(bounds, [1000, 400]);

      return {
        center: [center[1], center[0]],
        zoom: zoom - 0.2,
      };
    } catch (error) {
      log(error);
      return { center: [0, 0], zoom: 2 };
    }

  }, [data, latestEvent, eventsWithIssues, log]);

  return (
    <ErrorBoundary fallback={<div>Could not load map</div>}>
      <div style={{ height: '400px', position: 'relative' }}>
        <Map
          center={[center[0], center[1]]}
          zoom={zoom}
          animate
          attribution={false}
          zoomSnap={true}
        >
          {otherEvents.map((event, index) => {
            if (event.lat && event.lon) {
              let opacityClass;

              if (index < 5 || index >= otherEvents.length - 5) {
                opacityClass = 'opacity-60';
              } else {
                opacityClass = 'opacity-70';
              }

              return (
                <Marker style={{ pointerEvents: 'all' }} key={index} width={20} anchor={[event.lat, event.lon]}
                  payload={<div>{event.label}</div>}>
                  <WithHoverCard title="Event" renderContent={(
                    <>
                      <LabeledField label="Location" value={event.label}/>

                      {event.date && (
                        <LabeledField label="Time" value={parseOrFormat(event.date, DateFormat.HumanDateTime)}/>
                      )}
                    </>
                  )}>
                    <div
                      className={`p-1 bg-white border-2 border-gray-300 ${opacityClass} rounded-full shadow fill-gray-600 stroke-gray-200`}>
                      {/* Just a gray dot  */}
                      <div className="bg-gray-300 rounded-full w-2 h-2"/>
                    </div>
                  </WithHoverCard>
                </Marker>
              );
            }
          })}
          {data.meta.origin && data.meta.origin.lon && data.meta.origin.lon && (
            <Marker style={{ pointerEvents: 'all' }} width={20} anchor={[data.meta.origin.lat, data.meta.origin.lon]}
              payload={<div>{data.meta.origin.label}</div>}>
              <WithHoverCard title="Origin" renderContent={(
                <>
                  <LabeledField label="Name" value={data.meta.origin.label}/>
                </>
              )}>
                <div
                  className="p-1 bg-white border-2 border-blue-300 rounded-full shadow fill-blue-600 stroke-blue-200">
                  <HarborIcon/>
                </div>
              </WithHoverCard>
            </Marker>
          )}

          {data.meta.destination && data.meta.destination.lon && data.meta.destination.lon && (
            <Marker style={{ pointerEvents: 'all' }} width={20}
              anchor={[data.meta.destination.lat, data.meta.destination.lon]}
              payload={<div>{data.meta.destination.label}</div>}>
              <WithHoverCard title="Destination" renderContent={(
                <>
                  <LabeledField label="Name" value={data.meta.destination.label}/>
                </>
              )}>
                <div
                  className="p-1 bg-white border-2 rounded-full shadow border-emerald-300 fill-emerald-600 stroke-emerald-200">
                  <MapPinIcon style={{ width: 20, fill: 'inherit' }}/>
                </div>
              </WithHoverCard>
            </Marker>
          )}

          {eventsWithIssues.map((event, index) => {
            if (event.lat && event.lon) {
              return (
                <Marker style={{ pointerEvents: 'all' }} key={index} width={20} anchor={[event.lat, event.lon]}
                  payload={<div>{event.label}</div>}>
                  <WithHoverCard title="Event" renderContent={(
                    <>
                      <LabeledField label="Location" value={event.label}/>

                      {event.date && (
                        <>
                          <hr/>
                          <LabeledField label="Time" value={parseOrFormat(event.date, DateFormat.HumanDateTime)}/>
                        </>
                      )}
                      {event.issue && event.issue.delayInSec && (
                        <>
                          <hr/>
                          <LabeledField label="Days delay" value={(event.issue.delayInSec / 60 / 60 / 24).toFixed(2)}/>
                        </>
                      )}
                    </>
                  )}>
                    <div
                      className="p-1 bg-white border-2 border-red-300 rounded-full shadow fill-red-600 stroke-red-200">
                      {/* Just a red dot  */}
                      <ExclamationCircleIcon style={{ width: 20, fill: 'inherit' }}/>
                    </div>
                  </WithHoverCard>
                </Marker>
              );
            }
          })}
          {latestEvent.lat && latestEvent.lon && (
            <Marker style={{ pointerEvents: 'all' }} width={30} anchor={[latestEvent.lat ?? 0, latestEvent.lon ?? 0]}
              payload={<div>{latestEvent.transportID}</div>}>
              <WithHoverCard title="Current location" renderContent={(
                <>
                  <LabeledField label="Location" value={latestEvent.label}/>
                </>
              )}>
                <div
                  className="relative p-1 bg-white border-2 border-purple-300 rounded-full shadow fill-purple-600 stroke-red-200">
                  <ShipIcon/>
                </div>
              </WithHoverCard>
            </Marker>
          )}
        </Map>
      </div>
    </ErrorBoundary>
  );
};

