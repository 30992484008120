import { cva } from 'class-variance-authority';
import * as React from 'react';
import { forwardRef } from 'react';
import { w } from 'windstitch';

import { Tooltipped } from '@/Tooltip';
import { cn } from '~/utils/cn';

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        add: 'border-dashed border-gray-200',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export const CompoundButtonSibling = w.div(
  'relative inline-flex cursor-pointer items-center px-3 justify-center py-1.5 text-sm text-gray-500 focus:z-10',
  {
    variants: {
      variant: {
        default: 'bg-white',
        muted: 'bg-gray-50',
      },
      interactivity: {
        default: 'hover:bg-gray-100',
        static: '',
      },
    },
    defaultVariants: {
      interactivity: 'default',
      variant: 'default',
    },
  }
);

export const CompoundButton = w.div(
  'text-sm font-medium inline-flex rounded-md shadow-sm divide-x divide-gray-200 border border-gray-200 hover:border-gray-300 [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md aria-disabled:opacity-50 aria-disabled:cursor-not-allowed aria-disabled:pointer-events-none',
  {
    variants: {
      variant: {
        white: 'bg-white hover:bg-gray-50 text-gray-600',
      },
    },
    defaultVariants: {
      variant: 'white',
    },
  }
);

// export const CompoundButton =

export const CompoundButtonItem = w.button(
  `relative inline-flex items-center px-3 py-1.5 hover:border-left hover:bg-gray-50 focus:z-10
    disabled:opacity-50 disabled:pointer-events-none disabled:cursor-not-allowed
  `,
  {
    variants: {},
  }
);

export const CompoundButtonFooter = w.div(
  'text-xs text-gray-500 bg-gray-100 px-3 py-1.5 rounded-b-md -mt-1 border-t border-gray-200 w-full',
  {
    variants: {},
  }
);

export const Button = w.button(
  `
    inline-flex items-center
    text-center text-sm font-medium
    rounded-md
    shadow-sm
    disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none
    focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
    transition duration-200 ease-in-out
  `,
  {
    variants: {
      size: {
        xs: 'px-1.5 py-0.5 text-xs',
        sm: 'px-2 py-1',
        md: 'px-2.5 py-1.5',
        lg: 'px-4 py-3 text-lg',
      },
      variant: {
        primary:
          'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 text-white',
        secondary:
          'bg-purple-500 hover:bg-purple-600 :active:bg-purple-700 text-white',
        outline: 'border border-gray-300 bg-transparent text-gray-800',
        white:
          'bg-white ring-1 ring-inset ring-gray-200 hover:bg-gray-50 text-gray-600',
        gray:
          'bg-gray-50 ring-1 ring-inset ring-gray-200 hover:bg-gray-100 text-gray-700',
        error: 'bg-red-400 hover:bg-red-500 :active:bg-red-500 text-white',
        'error-outline': 'border border-red-400 bg-transparent text-red-400',
        'error-ghost':
          'bg-transparent text-red-400 hover:bg-red-50 !shadow-none',
        ghost: 'bg-transparent text-gray-500 hover:bg-gray-100 !shadow-none',
        add: 'border border-dashed border-gray-300 hover:bg-gray-50',
        addGhost: 'border border-dashed border-gray-300 hover:bg-gray-100 bg-gray-50',
      },
      disabled: {
        true: 'opacity-50 cursor-not-allowed pointer-events-none',
        false: '',
      },
    },
    defaultVariants: {
      disabled: false as any,
      variant: 'primary',
      color: 'primary',
      size: 'md',
    },
  }
);

type IconButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
  className?: string;
  variant?: 'default' | 'destructive';
  label: string;
  size?: 'sm' | 'md' | 'lg';
};

export const iconButtonVariant = cva(
  'border border-transparent border-solid rounded-md cursor-pointer h-fit hover:border-gray-200 flex items-center justify-center',
  {
    variants: {
      variant: {
        default: 'border-gray-200 shadow-sm transition  hover:shadow-md',
        destructive: '',
      },
      size: {
        sm: 'h-6 w-6 p-1',
        md: 'h-7 w-7 p-1',
        lg: 'h-12 w-12 p-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
  }
);

export const IconButton = forwardRef<
  React.ElementRef<typeof Button>,
  IconButtonProps
  // eslint-disable-next-line react/prop-types
>(({ className, label, variant = 'default', size = 'md', children, ...props }, ref) => (
  <Tooltipped label={label}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <button ref={ref}
      type="button"
      autoFocus={false}
      aria-label={label}
      className={cn(
        iconButtonVariant({ variant, size, className }),
      )}
      {...props}
    >
      {children}
    </button>
  </Tooltipped>
));
IconButton.displayName = 'IconButton';

export const ButtonGutter = w.div('gap-2', {
  variants: {
    variant: {
      'gray': 'bg-gray-100 p-1 rounded border-gray-200 border',
    },
  },
  defaultVariants: {
    variant: 'gray',
  },
});
