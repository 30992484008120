import { formatISO } from 'date-fns';

export const deepMerge = (target: any, source: any) => {
  // If target is not an object, initialize it as an empty object
  if (typeof target !== 'object' || target === null) {
    target = {};
  }

  for (const key in source) {
    // eslint-disable-next-line
    if (source.hasOwnProperty(key)) {
      if (source[key] instanceof Object) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }

  return target;
};

export const formatData = (data: any): any => {
  if (data === null || typeof data !== 'object') {
    return data;
  }

  if (data instanceof File) {
    return data;
  }

  if (data instanceof Date) {
    return formatISO(data, { format: 'extended' });
  }

  if (Array.isArray(data)) {
    return data.map(formatData);
  }

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, formatData(value)])
  );
};
