import { ClipboardIcon, TruckIcon } from '@heroicons/react/20/solid';
import { CloudArrowUpIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardHeader } from '@/Card';
import { ActivityIcon } from '@/Icon';
import { MutedText, Strong } from '@/Text';
import { DateFormat, useDate } from '~/hooks/useDate';
import { usePageProps } from '~/hooks/usePageProps';
import { Event, Order, Organization } from '~/types/types';

interface OrderEventsLogProps {
  order: Order;
  buyer?: Organization;
  seller?: Organization;
  events: Event[];
}

export const OrderEventAvatar = ({ event }: { event: Event }) => {
  let icon = <div/>;

  if (event.type === 'load_plan_added') {
    icon = <ClipboardIcon className="w-5 h-5 text-gray-600"/>;
  }

  if (event.type === 'quality_added') {
    icon = <CloudArrowUpIcon className="w-5 h-5 text-gray-600"/>;
  }

  if (event.type === 'order_arrived') {
    icon = <TruckIcon className="w-5 h-5 text-gray-600"/>;
  }

  return (
    <div className="relative">
      <div className="relative px-1">
        <div
          className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
          {icon}
        </div>
      </div>
    </div>
  );
};

export const OrderEventsLog = ({ buyer, seller, events }: OrderEventsLogProps) => {
  const { t } = useTranslation();
  const { parseOrFormat } = useDate();

  const { user } = usePageProps();

  if (!user.superadmin) {
    return null;
  }

  let filteredEvents = events;
  const supportedEvents = ['load_plan_added', 'quality_added', 'order_arrived'];
  filteredEvents = filteredEvents.filter((event) => supportedEvents.includes(event.type));

  // Ensure that if we have multiple received events, we only show the first "arrived event"
  const seenEvents = new Set();
  filteredEvents = filteredEvents.filter((event) => {
    if (event.type === 'order_arrived') {
      if (seenEvents.has('order_arrived')) {
        return false;
      }

      seenEvents.add('order_arrived');
    }

    return true;
  });

  return (
    <Card>
      <CardHeader
        title={t('activity')}
        description={t('activity_description')}
        renderIcon={<ActivityIcon className="w-6 h-6"/>}
      >
        <div/>
      </CardHeader>
      <CardBody>
        <div className="flow-root">
          <ul role="list">
            {filteredEvents.map((event, index) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  {index !== filteredEvents.length - 1 ? (
                    <span aria-hidden="true" className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"/>
                  ) : null}

                  <div className="relative flex items-start space-x-3">
                    {event.type === 'load_plan_added' && (
                      <>
                        <OrderEventAvatar event={event}/>

                        <div>
                          <Strong>
                            {seller?.title}
                          </Strong>
                          <div>
                            <MutedText className="!text-sm">
                              Uploaded load plan {parseOrFormat(event.created_at, DateFormat.DayFormat)}
                            </MutedText>
                          </div>
                        </div>
                      </>
                    )}

                    {event.type === 'quality_added' && (
                      <>
                        <OrderEventAvatar event={event}/>

                        <div>
                          <Strong>
                            {seller?.title}
                          </Strong>
                          <div>
                            <MutedText className="!text-sm">
                              Added quality data {parseOrFormat(event.created_at, DateFormat.DayFormat)}
                            </MutedText>
                          </div>
                        </div>
                      </>
                    )}

                    {event.type === 'order_arrived' && (
                      <>
                        <OrderEventAvatar event={event}/>

                        <div>
                          <Strong>
                            {buyer?.title}
                          </Strong>
                          <div>
                            <MutedText className="!text-sm">
                              Order has been received {parseOrFormat(event.created_at, DateFormat.DayFormat)}
                            </MutedText>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};
