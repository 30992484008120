import Correlation = App.Domain.Analytics.Correlations.Correlation;

export interface DataPoint {
  [key: string]: number | string | undefined;
}

export const extractUniqueXLabels = (data: DataPoint[], xLabelKey: string): string[] => {
  return data.map(d => d[xLabelKey]).filter((value, index, self) => self.indexOf(value) === index);
};

export const extractUniqueYLabels = (data: DataPoint[], xLabelKey: string) => {
  const allKeys = new Set<string>();
  data.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key !== xLabelKey) {
        allKeys.add(key);
      }
    });
  });
  return Array.from(allKeys);
};

export const formatCorrelationData = (correlation: Correlation): DataPoint[] => {
  const uniqueXLabels = [...new Set(correlation.items.map(item => item.xLabel))];
  const uniqueYLabels = [...new Set(correlation.items.map(item => item.yLabel))];

  return uniqueXLabels.map(xLabel => {
    const row = { [correlation.xType]: xLabel };
    uniqueYLabels.forEach(yLabel => {
      const item = correlation.items.find(item => item.xLabel === xLabel && item.yLabel === yLabel);
      row[yLabel] = item ? item.value : 0;
    });
    return row;
  });
};

export const formatDataToCorrelation = (data: DataPoint[], xLabelKey: string): Correlation => {
  const xType = xLabelKey;
  const items = data.map(d => {
    const { [xLabelKey]: xLabel, ...rest } = d;
    return Object.keys(rest).map(yLabel => ({
      xLabel,
      yLabel,
      value: rest[yLabel] as number,
    }));
  }).flat();

  return { xType, items };
};
