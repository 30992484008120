/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TriangleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/Button';
import { Card, CardBody, CardGutter } from '@/Card';
import { PlaceholderBox } from '@/Fallback';
import {
  ButtonIcon,
  ConstraintIcon, DeleteIcon, IconBox, IconBoxIcon, MiniLabelIcon,
  PlaceholderIcon,
} from '@/Icon';
import { MiniLabel } from '@/Label';
import { ConstraintsLabel } from '@/Labels/ConstraintsLabel';
import { Helper, Strong } from '@/Text';
import { ConstraintScopeLabel } from '~/Components/Spec/ConstraintScopeLabel';
import { FormatSpecField } from '~/Components/Spec/FormatSpec';
import { Field, FieldConstraint } from '~/types/types';
import { cn } from '~/utils/cn';
import { strongBorderColorByIndex, strongColorByIndex } from '~/utils/colors';
import { preprocessConstraint } from '~/utils/constraints';

interface SpecFieldCardProps {
  field: Field;
  onEnableEdit: () => void;
  disabled?: boolean;
  onRemove: () => void;
}

export const PreviewSpecFieldCard = ({
  field,
  onEnableEdit,
  disabled,
  onRemove,
}: SpecFieldCardProps) => {
  const { t } = useTranslation();
  const constraints = field.constraints as FieldConstraint[] ?? [];

  return (
    <div
      aria-label={`preview field-card for ${field.name}`}
      className={cn('grid shadow-sm grid-cols-[1fr_3fr] rounded', disabled && 'opacity-30 pointer-events-none')}
    >
      <div className="px-4 py-4 bg-gray-100 border border-r-0 border-gray-200 rounded-l">
        <div className="h-full gap-2">
          <div className="flex flex-col justify-between w-full h-full" aria-label="field name">
            <div>
              <Strong id={`fieldId::${field.id}`}>
                <FormatSpecField
                  field={field}
                />
              </Strong>

              <div>
                {field.defect_type && (
                  <MiniLabel>
                    <MiniLabelIcon icon={TriangleIcon}/>
                    {(t(field.defect_type) as string)}
                  </MiniLabel>
                )}
              </div>
            </div>

            <div className="mt-24 space-y-2">
              <div>
                <Button onClick={onRemove} type="button" variant="gray" className="flex justify-center w-full">
                  <ButtonIcon icon={DeleteIcon}/>

                  {t('remove_field')}
                </Button>
              </div>
              <div>
                <Button
                  disabled={disabled as any}
                  className="justify-center w-full" variant="gray" type="button" onClick={() => onEnableEdit()}>
                  Edit field
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 bg-white border border-gray-200 rounded-r">
        <div>
          <div className="-mx-4">
            <CardGutter className="rounded-tr">
              <Helper>
                Constraints
              </Helper>
            </CardGutter>
          </div>
        </div>

        {constraints.length > 0 && (
          <div className="py-4 space-y-4">
            {constraints.map((constraint, index) => (
              <div key={constraint.id}>
                <Card aria-label="preview constraint container" className={cn('border-l-4', strongBorderColorByIndex(index))}>
                  <CardBody size="xs">
                    <div className="flex items-center gap-3">
                      <IconBox
                        className={cn(
                          '!min-w-8 !h-8 flex items-center justify-center !border-0',
                          strongColorByIndex(index),
                        )}
                      >
                        <IconBoxIcon icon={ConstraintIcon}/>
                      </IconBox>

                      <div className="flex-grow">
                        <Strong className="leading-none">
                          Field Constraint {index + 1}
                        </Strong>

                        <div className="flex justify-between">
                          <div>
                            <ConstraintsLabel constraint={preprocessConstraint(constraint, field.type)} field={field}/>
                          </div>

                          <div>
                            <ConstraintScopeLabel constraint={constraint}/>
                          </div>
                        </div>
                      </div>
                    </div>

                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        )}

        {constraints.length === 0 && (
          <div className="py-4">
            <PlaceholderBox
              title="Unconstrained field"
              description="This field has no specifications for any produce set. Add constraints to define acceptable ranges or values for this field."
              icon={<PlaceholderIcon icon={ConstraintIcon}/>}
            />
          </div>
        )}
      </div>
    </div>
  );
};
