import { w } from 'windstitch';

export const Container = w.div(
  `
    mx-auto
  `,
  {
    variants: {
      size: {
        md: 'max-w-screen-xl px-2 sm:px-4',
        lg: 'max-w-7xl px-2 sm:px-4',
        xl: 'max-w-screen-2xl px-2 sm:px-4',
        full: 'w-full md:px-16 px-2',
      },
    },
    defaultVariants: {
      size: 'xl',
    },
  }
);
