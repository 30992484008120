import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithInput } from '@/Input';
import { BaseAction } from '~/Actions/BaseAction';
import { useCommandAction } from '~/hooks/useCommandAction';

interface CommandAttributeInputProps {
  action: typeof BaseAction;
  target: any;
  attribute: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
}

/**
 * An abstraction around an Input component that allows for easy updating of a single attribute of a target.
 * @param action
 * @param target
 * @param attribute
 * @param defaultValue
 * @param disabled
 * @param placeholder
 * @constructor
 */
export const CommandAttributeInput: React.FC<CommandAttributeInputProps> = ({
  action,
  target,
  attribute,
  defaultValue,
  disabled,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [commandInstance, { loading }] = useCommandAction(action, target);

  const handleChange = async (value: string) => {
    await commandInstance.execute({ [attribute]: value });
  };

  return (
    <WithInput
      disabled={disabled || commandInstance.disabled()}
      defaultValue={defaultValue || target[attribute] || ''}
      isLoading={loading}
      onChange={handleChange}
      hideInitially
      placeholder={placeholder as any || t('enter_value') as any}
    >
      {target[attribute] || defaultValue || t('no_value')}
    </WithInput>
  );
};
