import { useTranslation } from 'react-i18next';

import { Card, CardBody, CardGutter, CardHeaderContainer, CardPanels } from '@/Card';
import { QualityIssuesIcon, RegionIcon, SellerIcon, SimpleCardHeaderIcon, UserIcon } from '@/Icon';
import { CardDescription, CardTitle, MutedText, Strong } from '@/Text';
import QualityIssuesModule = App.Domain.Reports.Modules.QualityIssues.QualityIssuesModule;

import { Alert, AlertDescription, AlertTitle } from '@/Alert';
import { GenericPicker, OptionBaseType } from '@/Pickers/GenericPicker';
import { formatDataToCorrelation } from '~/Components/Analytics/Charts/Correlations/correlation-utils';
import { harvestQualityData, sampleRecommendation } from '~/Components/Analytics/Charts/Correlations/demo-data';
import { QualityCorrelationGraph } from '~/Components/Analytics/Charts/Correlations/QualityCorrelationGraph';
import { RelativeDifferenceLabel } from '~/Components/Analytics/RelativeDifferenceLabel';
import Section from '~/Components/Common/Section';
import { useAction } from '~/hooks/useAction';
import { EntityScopeType } from '~/types/types';
import { DeepPartial } from '~/utils/utils';

import { ReportsFilter } from '../../types';
import { QualityIssuesBreakdown } from './QualityIssuesBreakdown';
import ReportsPageOutput = App.Http.Controllers.Reports.ReportsPageOutput;

interface QualityIssuesCardProps {
  module: QualityIssuesModule;
}

export const QualityIssuesCard = ({ module }: QualityIssuesCardProps) => {
  const { t } = useTranslation();

  const scope = module.config.entityScopeType;

  const { reload, loading } = useAction<DeepPartial<ReportsFilter>, ReportsPageOutput>('', {
    withParams: true,
    only: ['issuesModule'],
  });

  const handleScopeChange = (entityScopeType: EntityScopeType | null) => {
    reload({
      overrideIssuesConfig: {
        entityScopeType: entityScopeType ?? undefined,
      },
    });
  };

  const handleZoomInRegion = (regionId: string) => {
    reload({
      overrideIssuesConfig: {
        entityScopeType: 'seller',
        scope: {
          zoomRegionId: regionId,
        },
      },
    });
  };

  const handleZoomOutRegion = () => {
    reload({
      overrideIssuesConfig: {
        entityScopeType: 'region',
        scope: {
          zoomRegionId: null,
        },
      },
    });
  };

  console.log(module.config.scope?.zoomRegion);

  return (
    <Section
      icon={QualityIssuesIcon}
      defaultOpen={false}
      title={t('quality_issues')}
      description={t('reports.quality_issues_description')}
      showCollapsed
      enableToggle
    >
      <div className="grid gap-4">
        <Card isLoading={loading}>
          <CardHeaderContainer size="sm">
            <div className="flex items-center justify-between gap-2">
              <div>
                <SimpleCardHeaderIcon icon={QualityIssuesIcon}/>
                <MutedText>
                  {t('reports.quality_issues')}
                </MutedText>
              </div>
            </div>
          </CardHeaderContainer>
          <CardBody bordered="y">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle>
                  {t('reports.quality_per_supplier')}
                </CardTitle>
                <CardDescription>
                  {t('reports.quality_issues_description')}
                </CardDescription>
              </div>
            </div>
          </CardBody>
          <CardGutter className="justify-end flex">
            <div className="flex space-x-2">

              {!!module.config.scope?.zoomRegion && (
                <div className="flex max-w-120">
                  <GenericPicker
                    showClear={true}
                    loading={loading}
                    icon={UserIcon}
                    value={module.config.scope.zoomRegion.index_code}
                    onChange={() => {
                      handleZoomOutRegion();
                    }}
                    showTitleOnSelected
                    title={t('region')}
                    options={[
                      {
                        label: module.config.scope.zoomRegion.label,
                        value: module.config.scope.zoomRegion.index_code,
                        icon: RegionIcon,
                      },
                    ]}
                    emptyMessage=""
                    placeholder=""
                  />
                </div>
              )}

              <div className="flex max-w-120">
                <GenericPicker<OptionBaseType, EntityScopeType>
                  showClear={false}
                  loading={loading}
                  icon={UserIcon}
                  value={scope}
                  onChange={handleScopeChange}
                  showTitleOnSelected
                  title={t('compare')}
                  options={[
                    {
                      label: 'By seller',
                      value: 'seller' as EntityScopeType,
                      icon: SellerIcon,
                      subLabel: 'Compare between sellers',
                    },
                    {
                      label: 'By region',
                      value: 'region' as EntityScopeType,
                      icon: RegionIcon,
                      subLabel: 'Compare between regions',
                    },
                  ]}
                  emptyMessage=""
                  placeholder=""
                />
              </div>
            </div>
          </CardGutter>
          <CardPanels columns="2_1">
            <CardBody>
              {module.results.entityBreakdowns && (
                <QualityIssuesBreakdown
                  handleZoomInRegion={handleZoomInRegion}
                  breakdowns={module.results.entityBreakdowns}
                />
              )}
            </CardBody>
            <CardBody bordered="left" variant="muted">
              <Alert variant="good">
                <AlertTitle>
                  {t('reports.quality_issues_looks_good')}
                </AlertTitle>

                <AlertDescription>
                  {t('reports.quality_issues_looks_good_description')}
                </AlertDescription>
              </Alert>

              <div className="mt-4">
                <div>
                  <Strong>
                    Total issues
                  </Strong>
                </div>

                <div className="flex items-center gap-4 justify-between">
                  <div>
                    <MutedText>
                      {module.results.totalIssuesCount}
                    </MutedText>
                  </div>

                  <div>
                    <RelativeDifferenceLabel difference={module.results.relativeDifference}/>
                  </div>
                </div>
              </div>
            </CardBody>
          </CardPanels>
        </Card>

        <div className="grid grid-cols-2 gap-4">
          {module.results.correlation && (
            <Card>
              <QualityCorrelationGraph
                title="Field co-occurrence"
                description="A correlation matrix showing the relationship between supplier performance and quality metrics"
                correlations={module.results.correlation}
                correlationType="issue_to_issue"
              />
            </Card>
          )}
          <Card>
            <QualityCorrelationGraph
              title="Harvest correlations"
              theme="green"
              description="The correlation between harvest conditions and quality issues."
              xLabel="condition"
              correlations={formatDataToCorrelation(harvestQualityData, 'condition')}
              recommendation={sampleRecommendation}
              correlationType="harvest_to_field"
            />
          </Card>
        </div>

      </div>
    </Section>
  );
};
