import Correlation = App.Domain.Analytics.Correlations.Correlation;
import { Recommendation } from '~/Components/AI/Recommendations/RecommendationPrompt';
import { DataPoint } from '~/Components/Analytics/Charts/Correlations/correlation-utils';

export const harvestQualityData: DataPoint[] =
  [
    {
      condition: 'lackingRainfall',
      lowBrix: 0.75,
      outOfSpec: 0.80,
      smallerSize: 0.85,
      poorColor: 0.70,
      reducedShelfLife: 0.65,
      flavorDefects: 0.60,
    },
    {
      condition: 'lowSunExposure',
      lowBrix: 0.70,
      outOfSpec: 0.65,
      smallerSize: 0.55,
      poorColor: 0.80,
      reducedShelfLife: 0.50,
      flavorDefects: 0.75,
    },
    {
      condition: 'extendedDrought',
      lowBrix: 0.90,
      outOfSpec: 0.85,
      smallerSize: 0.95,
      poorColor: 0.75,
      reducedShelfLife: 0.80,
      flavorDefects: 0.70,
    },
    {
      condition: 'excessiveHeat',
      lowBrix: 0.60,
      outOfSpec: 0.75,
      smallerSize: 0.70,
      poorColor: 0.65,
      reducedShelfLife: 0.85,
      flavorDefects: 0.80,
    },
    {
      condition: 'frostDamage',
      lowBrix: 0.50,
      outOfSpec: 0.90,
      smallerSize: 0.60,
      poorColor: 0.85,
      reducedShelfLife: 0.75,
      flavorDefects: 0.95,
    },
    {
      condition: 'soilNutrientDeficiency',
      lowBrix: 0.85,
      outOfSpec: 0.70,
      smallerSize: 0.75,
      poorColor: 0.90,
      reducedShelfLife: 0.60,
      flavorDefects: 0.85,
    },
    {
      condition: 'pestInfestation',
      lowBrix: 0.55,
      outOfSpec: 0.95,
      smallerSize: 0.50,
      poorColor: 0.60,
      reducedShelfLife: 0.90,
      flavorDefects: 0.85,
    },
    {
      condition: 'irregularIrrigation',
      lowBrix: 0.80,
      outOfSpec: 0.75,
      smallerSize: 0.65,
      poorColor: 0.55,
      reducedShelfLife: 0.70,
      flavorDefects: 0.60,
    },
  ];

export const sampleRecommendation: Recommendation = {
  title: 'Frost Damage lead cause of flavor defects',
  description: 'Mid-harvest frost damage is strongly correlated with flavor defects. Consider implementing frost protection measures to improve flavor quality.',
  level: 'info',
  confidence: 0.95,
};

export const poRecommendation: Recommendation = {
  title: 'Future order risk due to weather conditions',
  description: 'This purchase order (PO), scheduled to arrive on November 17th 2024 at Rotterdam, from Dana in Morocco will be at risk of being rejected (RAG: Red) with confidence of 83.27%. Reason: In the last three months, a lack of rain-fall in the grower region in the season of the last 3 months have projected a future risk of higher Dry Bruising (85%), lower Brix (76%) and 4 other quality projections.',
  level: 'info',
  confidence: 0.95,
};
