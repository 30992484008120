import { ReactNode, useCallback } from 'react';

import { useAction } from '~/hooks/useAction';
import { usePageProps } from '~/hooks/usePageProps';

interface ParamsSetterProps<T, V> {
  reloadKey?: string;
  dataPrefix?: string;
  dataKey: string;
  children: (props: {
    data: T;
    loading: boolean;
    onChange: (value: V) => void;
  }) => ReactNode;
}

// Helper function to safely access nested properties
const getNestedValue = (obj: any, path: string, prefix?: string): any => {

  // If there is no prefix, return the object itself
  if (!prefix) return undefined;

  // Get the nested value based on the prefix
  const nested = prefix.split('.').reduce((acc, part) => acc && acc[part], obj);

  // Get the value based on the path
  return path.split('.').reduce((acc, part) => acc && acc[part], nested);
  // return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

/**
 * ParamsSetter
 *
 * A higher-order component that facilitates direct URL parameter manipulation
 * and data fetching for child components. It provides a way to sync component
 * state with URL parameters and automatically reload data when these parameters change.
 *
 * @component
 * @template T - The type of the data being managed
 * @template V - The type of the value being set (usually inferred from the onChange function)
 *
 * @param {Object} props
 * @param {string} [props.reloadKey] - Key used to trigger specific data reloading.
 * @param {string} [props.dataPrefix] - Prefix for accessing nested data in pageProps.
 * @param {string} props.dataKey - Key path for accessing and updating specific data.
 * @param {Function} props.children - Render prop function that receives data, loading state, and onChange handler.
 *
 * @example
 * <ParamsSetter
 *   reloadKey="userSelection"
 *   dataPrefix="pageProps"
 *   dataKey="selectedUsers"
 * >
 *   {({ data, loading, onChange }) => (
 *     <GenericPicker
 *       value={data}
 *       onChange={onChange}
 *       loading={loading}
 *       options={users}
 *       title="Select Users"
 *       isMulti={true}
 *     />
 *   )}
 * </ParamsSetter>
 *
 * @note
 * This component is particularly useful for creating components that need to
 * directly manipulate URL parameters. It handles the complexity of updating
 * URL parameters and reloading relevant data, allowing child components to
 * focus on rendering and user interactions.
 *
 * @note
 * The `onChange` function provided to children will automatically update the
 * URL parameters based on the `dataKey` prop. This enables deep linking and
 * easy state management through URL parameters.
 *
 * @note
 * When used with components like GenericPicker, ParamsSetter can create a
 * seamless integration between user interactions, URL state, and server-side
 * data fetching, enabling powerful and flexible UI patterns.
 */
export const ParamsSetter = ({ children, reloadKey, dataPrefix, dataKey }: ParamsSetterProps<any, any>) => {
  const pageProps = usePageProps();

  const { reload, loading } = useAction<any, any>('', {
    withParams: true,
    only: reloadKey ? [reloadKey] : undefined,
  });

  const data = getNestedValue(pageProps, dataKey, dataPrefix);

  const handleChange = useCallback((value: any) => {
    // Create an object with the nested structure based on dataKey
    const updateObject = dataKey.split('.').reduceRight((acc, part) => ({ [part]: acc }), value);

    // Call reload with the new value
    reload(updateObject);
  }, [reload, dataKey]);

  console.log(pageProps);

  return (
    <>
      {children({
        data,
        loading,
        onChange: handleChange,
      })}
    </>
  );
};
