/**
 * React custom hook which returns the page props
 */

import { usePage } from '@inertiajs/react';

import { PageProps } from '~/types/types';

interface PagePropsWithUrl extends PageProps {
  url: string;
}

export const usePageProps = <T extends PageProps>(props?: T) => {
  const { url, props: hookProps = {} } = usePage<T>();

  return {
    url: url,
    ...hookProps,
    ...props,
  } as T & PagePropsWithUrl;
};
