import {
  DropdownContent,
  DropdownGroup,
  DropdownGroupHeader,
  DropdownItem,
  DropdownRoot, DropdownSeparator,
  DropdownTrigger,
} from '@/DropdownMenu';
import { DropdownIcon, FeedbackIcon, HelpIcon, PrivacyIcon, ToSIcon } from '@/Icon';

export const FAB = () => {
  return (
    <div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <button
            className="fixed z-10 flex items-center justify-center p-4 text-lg text-gray-700 transition-all bg-white rounded-full shadow-lg h-11 w-11 bottom-4 right-4 bg-primary-500 hover:bg-primary-600">
            ?
          </button>
        </DropdownTrigger>
        <DropdownContent side="top">
          <DropdownGroup>
            <DropdownGroupHeader>Assist</DropdownGroupHeader>
            <DropdownItem>
              <DropdownIcon icon={HelpIcon}/>
              <a
                href='https://agstacked-help.notion.site/7b341bdea2424e0cbeeaa205262613f4?v=ab2d4a2245b54fcaa7dd1c945f1d5efd&pvs=4'
                target="_blank" rel="noreferrer">
                AgStacked help center
              </a>
            </DropdownItem>
            <DropdownSeparator/>
          </DropdownGroup>

          <DropdownGroup>
            <DropdownGroupHeader>Links</DropdownGroupHeader>
            <DropdownItem>
              <DropdownIcon icon={FeedbackIcon}/>
              <a className='w-full' href='https://www.agstacked.com/contact' target="_blank" rel="noreferrer">
                Feedback
              </a>
            </DropdownItem>

            <DropdownItem>
              <DropdownIcon icon={PrivacyIcon}/>

              <a className='w-full' href='https://www.agstacked.com/privacy-policy' target="_blank" rel="noreferrer">
                Privacy
              </a>
            </DropdownItem>

            <DropdownItem>
              <DropdownIcon icon={ToSIcon}/>

              <a className='w-full' href='https://www.agstacked.com/privacy-policy' target="_blank" rel="noreferrer">
                Terms of Service
              </a>
            </DropdownItem>
          </DropdownGroup>
        </DropdownContent>
      </DropdownRoot>
    </div>
  );
};

