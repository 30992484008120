import { z } from 'zod';

import { validateZodKeys } from '~/hooks/Api/validateZod';

type SchemaLocalStorageOptions = {
  type: 'schema';
  schema: z.Schema;
};

type AllLocalStorageOptions = {
  type: 'all';
};

export type LocalStorageOptions = SchemaLocalStorageOptions | AllLocalStorageOptions;

export const storeLocal = (path: string, paramsData: any, localStorageOptions: LocalStorageOptions) => {
  // Added schema check to only save params that are in the schema
  // This makes our overviews return to the first page when reloading
  if (localStorageOptions.type === 'schema') {
    const { schema } = localStorageOptions;
    const zKeys = validateZodKeys(schema);

    paramsData = Object.fromEntries(
      Object.entries(paramsData).filter(([key]) => {
        return zKeys.includes(key);
      })
    ) as any;
  }

  handleSaveLocalStorage(path, paramsData);
};

const handleSaveLocalStorage = (path: string, paramsData: object) => {
  const splitPath = path.split('/');
  const page = splitPath[splitPath.length - 1];
  const filteredParams = Object.fromEntries(
    Object.entries(paramsData).filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return !!value;
    })
  );

  if (Object.keys(filteredParams).length === 0) {
    localStorage.removeItem(page);
  } else {
    localStorage.setItem(page, JSON.stringify(filteredParams));
  }
};
