/* eslint-disable react/prop-types */
import { ErrorBoundary } from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import { ReactNode, useEffect } from 'react';

import { DebugJSONModal } from '@/JSON';
import { Nav } from '@/Nav/Nav.js';
import { makeTopLinks } from '@/Nav/nav-items';
import { MainNavItem, Navbar } from '@/Nav/Navbar';
import { DebugProvider, LocalDebugModal } from '~/Components/Common/LocalDebugModal';
import BreakpointIndicator from '~/Components/Common/Tools/BreakpointIndicator';
import { FAB } from '~/Components/FAB';
import { ServerErrorProvider } from '~/hooks/ServerErrorProvider';
import { useAppTitle } from '~/hooks/useAppTitle.js';
import { usePageProps } from '~/hooks/usePageProps';
import { usePageTracking } from '~/hooks/usePageTracking.js';
import { OrganizationStatus } from '~/types/types';

import { GeneratingModal } from './GeneratingModal.tsx';
import { OnboardingModal } from './OnboardingModal';

interface DashboardLayoutProps {
  children: ReactNode;
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const posthog = usePostHog();
  const props = usePageProps();
  const {
    user,
    organization,
    show_onboard_modal,
    app: { path },
  } = props;

  const topLinks = makeTopLinks(organization.id);

  useAppTitle();
  usePageTracking(path);
  useEffect(() => {
    if (!posthog) {
      return console.warn('Posthog is not initialized');
    }
    posthog.identify(user.id);
  }, [posthog]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 [&>*]:w-full">
      <DebugProvider pageProps={props}>
        <ServerErrorProvider>
          <LocalDebugModal/>
          <Navbar
            user={user}
            organization={organization}
            renderNavLinks={(
              <div className="flex gap-3">
                {topLinks.map((link) => (
                  <MainNavItem key={link.name} link={link}/>
                ))}
              </div>
            )}
          />
          <FAB/>
          <BreakpointIndicator/>
          <OnboardingModal
            user={user}
            organization={organization}
            show={show_onboard_modal ?? false}
          />

          {organization.status === OrganizationStatus.GENERATING && (
            <GeneratingModal/>
          )}

          {organization.status === OrganizationStatus.GENERATING ? (
            <ErrorBoundary
              onError={() => {
                console.log('ignore');
              }}
              fallback={<div>Generating new data</div>}
            >
              {children}
            </ErrorBoundary>
          ) : (
            <>{children}</>
          )}
        </ServerErrorProvider>
      </DebugProvider>
    </div>
  );
};
