export type Theme = 'emerald' | 'red' | 'gray' | 'orange' | 'blue';

export const themeTokens = {
  red: '#F43F5E', // rose-500,
  green: '#10B981', // green-500,
};

export const themes = [
  'blue',
  'emerald',
  'yellow',
  'slate',
  'gray',
  'indigo',
  'purple',
  'orange',
  'red',
  'pink',
  'cyan',
  'teal',
  'lime',
  'amber',
  'green',
  'fuchsia',
];

/**
 * Return color by index.
 *
 * Order of colors is defined in the `themes` array.
 * @param index
 */
export const strongColorByIndex = (index: number) => {
  if (index === 0) {
    return '!bg-blue-500 !text-blue-50';
  }

  if (index === 1) {
    return '!bg-emerald-500 !text-emerald-50';
  }

  if (index === 2) {
    return '!bg-yellow-500 !text-yellow-50';
  }

  if (index === 3) {
    return '!bg-slate-500 !text-slate-50';
  }

  if (index === 4) {
    return '!bg-gray-500 !text-gray-50';
  }

  if (index === 5) {
    return '!bg-indigo-500 !text-indigo-50';
  }

  if (index === 6) {
    return '!bg-purple-500 !text-purple-50';
  }

  if (index === 7) {
    return '!bg-orange-500 !text-orange-50';
  }

  if (index === 8) {
    return '!bg-red-500 !text-red-50';
  }

  if (index === 9) {
    return '!bg-pink-500 !text-pink-50';
  }

  if (index === 10) {
    return '!bg-cyan-500 !text-cyan-50';
  }

  return '!bg-gray-500 !text-gray-50';
};

export const strongBorderColorByIndex = (index: number) => {
  if (index === 0) {
    return 'border-blue-500';
  }

  if (index === 1) {
    return 'border-emerald-500';
  }

  if (index === 2) {
    return 'border-yellow-500';
  }

  if (index === 3) {
    return 'border-slate-500';
  }

  if (index === 4) {
    return 'border-gray-500';
  }

  if (index === 5) {
    return 'border-indigo-500';
  }

  if (index === 6) {
    return 'border-purple-500';
  }

  if (index === 7) {
    return 'border-orange-500';
  }

  if (index === 8) {
    return 'border-red-500';
  }

  if (index === 9) {
    return 'border-pink-500';
  }

  if (index === 10) {
    return 'border-cyan-500';
  }

  return 'border-gray-500';
};

export type DefaultTheme = typeof themes[number];
