import { Issue, OrderDataStream } from '~/types/types';
import { logError } from '~/utils/logger';

/**
 * Uses the generic data stream type to calculate the number of issues in the data streams.
 */
export const calculateGenericIssuesCount = (dataStreams: OrderDataStream<any>[]) => {
  try {
    return dataStreams.reduce((acc, dataStream) => {
      const data = dataStream.issues?.length ?? 0;

      return acc + data;
    }, 0);
  } catch (error) {
    logError(error);
    return 0;
  }
};

export const getDataStreamIssues = (dataStreams: OrderDataStream<any>[]): Issue[] => {
  const allIssues = [];

  for (const dataStream of dataStreams) {
    if (dataStream.issues) {
      allIssues.push(...dataStream.issues);
    }
  }

  return allIssues;
};
